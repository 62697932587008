<template>
  <div>
    <el-form :inline="true">
      <!-- 添加/搜索区域 -->
      <el-form-item>
        <el-button @click="addcarousel">{{ $t('xinzeng') }}</el-button>
      </el-form-item>
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      :data="carousellist"
      border
      style="width: 100%;"
    >
      <el-table-column prop="id" label="#"></el-table-column>
      <!-- <el-table-column prop="jumpurl" :label="$t('cs.tzdz')"></el-table-column> -->
      <el-table-column prop="imageurl_zh" label="中文">
        <template slot-scope="{ row }"
          ><img :src="row.imageurl_zh" height="120px"
        /></template>
      </el-table-column>
      <el-table-column prop="imageurl_hk" label="繁体">
        <template slot-scope="{ row }"
          ><img :src="row.imageurl_hk" height="120px"
        /></template>
      </el-table-column>
      <el-table-column prop="imageurl_en" label="英文">
        <template slot-scope="{ row }"
          ><img :src="row.imageurl_en" height="120px"
        /></template>
      </el-table-column>
      <el-table-column prop="imageurl_th" label="泰语">
        <template slot-scope="{ row }"
          ><img :src="row.imageurl_th" height="120px"
        /></template>
      </el-table-column>
      <el-table-column prop="imageurl_kor" label="韩语">
        <template slot-scope="{ row }"
          ><img :src="row.imageurl_kor" height="120px"
        /></template>
      </el-table-column>
      <el-table-column prop="imageurl_jp" label="日语">
        <template slot-scope="{ row }"
          ><img :src="row.imageurl_jp" height="120px"
        /></template>
      </el-table-column>
      <el-table-column prop="imageurl_fra" label="法语">
        <template slot-scope="{ row }"
          ><img :src="row.imageurl_fra" height="120px"
        /></template>
      </el-table-column>
      <el-table-column prop="imageurl_jp" label="日语">
        <template slot-scope="{ row }"
          ><img :src="row.imageurl_jp" height="120px"
        /></template>
      </el-table-column>
      <el-table-column :label="$t('caozuo')" width="150px">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('shanchu')"
            placement="top"
            :enterable="false"
          >
            <i class="el-icon-close" @click="del(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 添加弹窗区域 -->
    <el-dialog
      :title="$t('xinzeng')"
      :visible="dialogvisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="addform"
        label-width="150px"
        ref="addref"
        :rules="addrules"
      >
        <!-- <el-form-item :label="$t('cs.tzdz')" prop="jumpurl">
          <el-input v-model="addform.jumpurl"></el-input>
        </el-form-item> -->

        <el-form-item label="中文" prop="imageurl_zh">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/wallet/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload_zh"
          >
            <img
              v-if="addform.imageurl_zh"
              :src="addform.imageurl_zh"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="繁体" prop="imageurl_hk">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/wallet/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload_hk"
          >
            <img
              v-if="addform.imageurl_hk"
              :src="addform.imageurl_hk"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="英文" prop="imageurl_en">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/wallet/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload_en"
          >
            <img
              v-if="addform.imageurl_en"
              :src="addform.imageurl_en"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>


        <el-form-item label="泰语" prop="imageurl_th">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/wallet/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload_th"
          >
            <img
              v-if="addform.imageurl_th"
              :src="addform.imageurl_th"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="法语" prop="imageurl_fra">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/wallet/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload_fra"
          >
            <img
              v-if="addform.imageurl_fra"
              :src="addform.imageurl_fra"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

       
        <el-form-item label="西班牙" prop="imageurl_spa">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/wallet/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload_spa"
          >
            <img
              v-if="addform.imageurl_spa"
              :src="addform.imageurl_spa"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="韩语" prop="imageurl_spa">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/wallet/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload_kor"
          >
            <img
              v-if="addform.imageurl_kor"
              :src="addform.imageurl_kor"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="日语" prop="imageurl_spa">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/wallet/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload_jp"
          >
            <img
              v-if="addform.imageurl_jp"
              :src="addform.imageurl_jp"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">{{ $t('tijiao') }}</el-button>
        <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
    <!-- 编辑弹窗区域 -->
    <el-dialog
      :title="$t('bianji')"
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="addform"
        label-width="150px"
        ref="editref"
        :rules="addrules"
      >
        <!-- <el-form-item :label="$t('cs.tzdz')" prop="jumpurl">
          <el-input v-model="addform.jumpurl"></el-input>
        </el-form-item> -->

        <el-form-item label="中文" prop="imageurl_zh">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/wallet/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload_zh"
          >
            <img
              v-if="addform.imageurl_zh"
              :src="addform.imageurl_zh"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="繁体" prop="imageurl_hk">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/wallet/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload_hk"
          >
            <img
              v-if="addform.imageurl_hk"
              :src="addform.imageurl_hk"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="英文" prop="imageurl_en">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/wallet/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload_en"
          >
            <img
              v-if="addform.imageurl_en"
              :src="addform.imageurl_en"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>


        <el-form-item label="泰语" prop="imageurl_th">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/wallet/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload_th"
          >
            <img
              v-if="addform.imageurl_th"
              :src="addform.imageurl_th"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="法语" prop="imageurl_fra">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/wallet/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload_fra"
          >
            <img
              v-if="addform.imageurl_fra"
              :src="addform.imageurl_fra"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

       
        <el-form-item label="西班牙" prop="imageurl_spa">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/wallet/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload_spa"
          >
            <img
              v-if="addform.imageurl_spa"
              :src="addform.imageurl_spa"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="韩语" prop="imageurl_spa">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/wallet/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload_kor"
          >
            <img
              v-if="addform.imageurl_kor"
              :src="addform.imageurl_kor"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="日语" prop="imageurl_spa">
          <el-upload
            class="avatar-uploader"
            :action="weburl + '/admin/wallet/uploadimg'"
            :show-file-list="false"
            :headers="headerobj"
            :on-success="upload_jp"
          >
            <img
              v-if="addform.imageurl_jp"
              :src="addform.imageurl_jp"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>





        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editsubmit">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="editclose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 分页请求数据对象
      queryinfo: {
        query: '',
        page: 1
      },
      carousellist: [],
      total: 0,
      loading: true,
      // 添加/编辑数据对象
      addform: {
        jumpurl: '',
        imageurl_zh: '',
        imageurl_en: '',
        imageurl_hk: '',
        imageurl_th: '',
        imageurl_spa: '',
        imageurl_fra: '',
        imageurl_kor: '',
        imageurl_jp: '',
      },
      // 编辑的id
      editid: '',
      dialogvisible: false,
      addrules: {
        jumpurl: [
          { required: true, message: '请填写跳转地址', trigger: 'blur' }
        ],
        imageurl: [{ required: true, message: '请上传图片', trigger: 'blur' }]
      },
      editdialogvisible: false,
      // 设置图片上传的token
      headerobj: {
        Authorization: window.sessionStorage.getItem('token')
      }
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/admin/config/carouselconfig', {
        params: this.queryinfo
      })
      this.carousellist = data.data.list
      this.loading = false
    },
    // 添加分类弹窗
    async addcarousel() {
      this.dialogvisible = true
    },
    // 关闭添加弹窗
    handleClose() {
      this.$refs.addref.resetFields()
      this.dialogvisible = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },
    // 提交添加分类
    submit() {
      this.$refs.addref.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/config/addcarousel',
          this.addform
        )
        if (data) {
          if (data.code === 200) {
            this.$message.success(this.getlang(data.msg))
            this.handleClose()
            this.getlist()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 打开编辑弹窗
    async edit(id) {
      this.editdialogvisible = true
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get(
        '/admin/config/editcarousel/id/' + id
      )
      this.addform.jumpurl = data.data.jumpurl
      this.addform.imageurl_zh = data.data.imageurl_zh
      this.addform.imageurl_en = data.data.imageurl_en
      this.addform.imageurl_hk = data.data.imageurl_hk
      this.addform.imageurl_spa = data.data.imageurl_spa
      this.addform.imageurl_fra = data.data.imageurl_fra
      this.addform.imageurl_th = data.data.imageurl_th
      this.addform.imageurl_kor = data.data.imageurl_kor
      this.addform.imageurl_jp = data.data.imageurl_jp
      this.editid = data.data.id
      loading.close()
    },
    // 编辑提交
    editsubmit() {
      this.$refs.editref.validate(async valid => {
        if (!valid) return false
        this.addform.id = this.editid
        const { data } = await this.$http.post(
          '/admin/config/editcarousel',
          this.addform
        )
        if (data) {
          if (data.code === 200) {
            this.$message.success(this.getlang(data.msg))
            this.editclose()
            this.getlist()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
        this.editdialogvisible = false
      })
    },
    // 关闭编辑弹窗
    editclose() {
      this.$refs.editref.resetFields()
      this.editdialogvisible = false
    },
    // 删除操作
    del(id) {
      this.$confirm(this.$t('shanchutips'), this.$t('tishi'), {
        confirmButtonText: this.$t('queding'),
        cancelButtonText: this.$t('quxiao'),
        type: 'warning'
      }).then(async () => {
        const { data } = await this.$http.post('/admin/config/delcarousel', {
          id
        })
        // console.log(data)
        if (data) {
          if (data.code === 200) {
            this.$message.success(this.getlang(data.msg))
            this.getlist()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 图片上传成功操作
    upload_zh(res) {
      this.addform.imageurl_zh = res.data.url
    },
    upload_en(res) {
      this.addform.imageurl_en = res.data.url
    },
    upload_hk(res) {
      this.addform.imageurl_hk = res.data.url
    },
    upload_th(res) {
      this.addform.imageurl_th = res.data.url
    },
    upload_fra(res) {
      this.addform.imageurl_fra = res.data.url
    },
    upload_spa(res) {
      this.addform.imageurl_spa = res.data.url
    },
    upload_kor(res) {
      this.addform.imageurl_kor = res.data.url
    },
    upload_jp(res) {
      this.addform.imageurl_jp = res.data.url
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}
.el-select {
  min-width: 300px;
}
.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
}
.avatar-uploader:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
